<template>
	<h2 class="H2title">
		<p>{{subtitle}}</p>
		<span id="">
			{{title}}
		</span>
		<div id="" class="guidtitle">
			<a href="/">首页></a><a>{{title}}</a>
		</div>
	</h2>
</template>

<script>
	export default{
		props: {
			title: {
				type: String,
				default: ''
			},
			subtitle:{
				type:String,
				default:'XISHAN SURVEY'
			}
		},
	}
</script>

<style lang="less">
	.H2title {
		text-align: center;
		width: 100%;
		padding: 90px 0 90px;
		position: relative;
		z-index: 0;
	
		.guidtitle {
			position: absolute;
			top: 185px;
			right: 0px;
	
			a {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #B2B2B2;
			}
		}
	
		p {
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: 128px;
			left: 0;
			font-size: 82px;
			line-height: 108px;
			font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
			font-weight: 300;
			color: rgba(203, 185, 124, 0.3);
			z-index: -1;
			font-family: Microsoft YaHei UI;
		}
	
		&::after {
			content: '';
			position: absolute;
			bottom: 120px;
			left: 0;
			width: 34%;
			height: 2px;
			background-color: #d8c6a3;
	
		}
	
		&::before {
			content: '';
			position: absolute;
			bottom: 120px;
			right: 0;
			width: 34%;
			height: 2px;
			background-color: #d8c6a3;
	
		}
	
		span {
			display: inline-block;
			height: 110px;
			width: 380px;
			font-size: 60px;
			text-align: center;
			color: #a27b2c;
			background: url(../assets/img/fontbg.png) no-repeat bottom;
	
		}
	}
</style>
