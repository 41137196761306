// 对axios 二次封装

import axios from 'axios';
import store from '../store/index'
// 封装的目的是封装公共的拦截器 ， 每个实例有单独自己拦截器
// 创建一个单独的实例，每次请求都使用这个方法来创建实例
import * as types from '../store/action-types'
import {getLocal} from './local'

//let loadingInstance;
// 当页面切换时 删除不必要的请求
class Http{
    constructor(){
        this.timeout = 30000; // 超时时间
        // 开发时和生产时采用不同的前缀发送请求                 本地代理地址    线上地址
        this.baseURL = process.env.NODE_ENV == 'development'?'/api':'https://ticket.ezhouxishan.com/';

        this.queue = {}; // 存放所有的请求队列 /getBannerList : true
    }
    mergeOptions(options){ // 合并参数
        return {
            timeout:this.timeout,
            baseURL:this.baseURL,
            ...options
        }
    }
    setInterceptor(instance,url){
        instance.interceptors.request.use((config)=>{
            
			let tokenInfo= localStorage.getItem('tokenInfo')
			tokenInfo =tokenInfo ? JSON.parse(tokenInfo) :null
			if(tokenInfo){
				 config.headers.token = tokenInfo.token;
			}
            return config;
        });
    
    }
    request(options){ // 用户的参数 + 默认参数 = 总共的参数
        const opts = this.mergeOptions(options);
        // 对请求的超时，切换取消请求  , loading
        const axiosInstance = axios.create(); // axios()
        // 添加拦截器
        this.setInterceptor(axiosInstance,opts.url);
        // 当调用axios.request 时 内部会创建一个 axios实例 并且给这个实例传入配置属性
        return axiosInstance(opts)
    }
    // 这两个方法只是对request方法 一个简写而已
    get(url,config = {}){ // params
        return this.request({
            url,
            method:'get',
            ...config
        })
    }
    post(url,data){  // data
        // 对data进行格式化
        return this.request({
            method:'post',
            url,
            data
        })
    }
}
export default new Http

