export default [
    {
        path: '/',
        name: 'Home',
        component: () => import(/*webpackChunkName:'home'*/'@/views/Home/Home.vue') // 会默认代码分割
    },
	{
	    path: '/survey',
	    name: 'Survey',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/Home/survey.vue') // 概况
	},
	{
	    path: '/romantic',
	    name: 'Romantic',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/Home/romantic.vue') // 神韵
	},{
	    path: '/hotspot',
	    name: 'Hotspot',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/Home/hotspot.vue') // 热点
	},{
	    path: '/mediaFocus',
	    name: 'MediaFocus',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/Home/mediaFocus.vue') // 媒体聚焦
	},{
	    path: '/humanity',
	    name: 'Humanity',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/humanity/humanity.vue') // 人文
	},
	
    {
        path: '/two',
        name: 'two',
        component: () => import(/*webpackChunkName:'home'*/'@/views/two/two.vue') // 会默认代码分割
    },
    {
        path: '/three',
        name: 'three',
        component: () => import(/*webpackChunkName:'home'*/'@/views/three/three.vue') // 会默认代码分割
    },{
        path: '/site',
        name: 'site',
        component: () => import(/*webpackChunkName:'home'*/'@/views/three/site.vue') // 会默认代码分割
    },
    {
        path: '/four',
        name: 'four',
        component: () => import(/*webpackChunkName:'home'*/'@/views/four/four.vue') // 会默认代码分割
    },
    {
        path: '/five',
        name: 'five',
        component: () => import(/*webpackChunkName:'home'*/'@/views/five/five.vue') // 会默认代码分割
    }, {
        path: '/new',
        name: 'new',
        component: () => import(/*webpackChunkName:'home'*/'@/views/new/news.vue') // 会默认代码分割
    },{
        path: '/newDetail',
        name: 'newDetail',
        component: () => import(/*webpackChunkName:'home'*/'@/views/new/detail.vue') // 会默认代码分割
    },{
        path: '/login',
        name: 'login',
        component: () => import(/*webpackChunkName:'home'*/'@/views/login.vue') // 会默认代码分割
    },{
        path: '/register',
        name: 'register',
        component: () => import(/*webpackChunkName:'home'*/'@/views/register.vue') // 会默认代码分割
    },{
        path: '/ticketDetail',
        name: 'ticketDetail',
        component: () => import(/*webpackChunkName:'home'*/'@/views/three/ticket.vue') // 会默认代码分割
    },
	{
	    path: '/ticketReserve',
	    name: 'ticketReserve',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/three/reserve.vue') // 会默认代码分割
	},{
	    path: '/peopleInfo',
	    name: 'PeopleInfo',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/peopleInfo/peopleInfo.vue') // 会默认代码分割
	},
	{
	    path: '/ordersPayment',
	    name: 'ordersPayment',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/orders/payment.vue')
	},
	{
	    path: '/gonglue',
	    name: 'gonglue',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/new/gonglue.vue') // 会默认代码分割
	},{
	    path: '/traffic',
	    name: 'traffic',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/traffic/traffic.vue') // 交通
	},
	{
	    path: '/food',
	    name: 'Food',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/food/food.vue') // 食物
	},
	{
	    path: '/specialty',
	    name: 'Specialty',
	    component: () => import(/*webpackChunkName:'home'*/'@/views/specialty/specialty.vue') // 食物
	},
    {
        path: '*',
        component: () => import(/*webpackChunkName:'404'*/'@/views/404.vue')
    }]