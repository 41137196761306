<template>
	<header class="headerbox">
		<img class="headerbg" src="../assets/img/navbg.png">
		<img class="coldimg" src="../assets/img/cold.png">

		<div class="header_inbox">
			<h1 class="on"><a href="/"><img style="width: 90px;height: 84px;" src="../assets/img/logo.png"></a></h1>
			<div class="navbox ">
				<ul>
					<div id="" class="lionActive" :style="{'left':lALeft+'px'}">

					</div>
					<li class=" " @mouseover="selectStyle (1) " @mouseout="OverStyle(1)">
						<a href="/" :class="navType=='/'?'lion':''">印象西山</a>
						<div id="" class="navsub">
							<p> <a href="/survey">西山概况</a></p>
							<!-- <p> <a href="/romantic">西山神韵</a></p> -->
							<p> <a href="/hotspot">景点图集</a></p>
							<p> <a href="/mediaFocus">媒体聚焦</a></p>
						</div>
					</li>
					<li class=" " @mouseover="selectStyle (2) " @mouseout="OverStyle(2)">
						<a href="/humanity" :class="navType=='/humanity'?'lion':''">人文西山</a>
					</li>
					<li class=" " @mouseover="selectStyle (3) " @mouseout="OverStyle(3)">
						<a href="/gonglue"
							:class="navType=='/four'||navType=='/gonglue'||navType=='/new'?'lion':''">足迹西山</a>
						<div id="" class="navsub">
							<p><a href="/four">游园景点</a></p>
							<p><a href="/gonglue">游园攻略</a></p>
							<p><a href="/new">游园游记</a></p>
						</div>
					</li>
					<li class=" " @mouseover="selectStyle (4) " @mouseout="OverStyle(4)">
						<a href="/traffic" :class="navType=='/traffic'?'lion':''">出行西山</a>
						<div id="" class="navsub">
							<p><a href="/traffic">交通</a></p>
							<p><a href="/site">酒店</a></p>
							<p><a href="/food">美食</a></p>
							<!-- <p><a href="/specialty">特产</a></p> -->
						</div>
					</li>
					<li class=" " @mouseover="selectStyle (5) " @mouseout="OverStyle(5)">
						<a href="/five" :class="navType=='/five'?'lion':''">西山服务</a>
						<div id="" class="navsub">
							<!-- <p> <a href="/traffic">地图</a></p> -->
							<p> <a href="/three">门票</a></p>
							<!-- <p> <a>商城</a></p> -->
						</div>
					</li>
				</ul>
			</div>

		</div>
		<div id="" class="weatherBox">
			<h3>{{WeatherInfo.temperature||''}} <i  class="iconfont ">&#xe660; </i> </h3>
			<h2>湖北·鄂州</h2>
			<div id="" class="weatherNum">
				<div id="">
					<i class="iconfont ">&#xe623;</i>
					<p>{{WeatherInfo.weather||''}} </p>
				</div>
				<div id="">
					<i class="iconfont ">&#xea32;</i>
					<p>{{WeatherInfo.windpower||''}}</p>
				</div>
				<div id="">
					<i class="iconfont ">&#xea34;</i>
					<p>{{WeatherInfo.humidity||''}}</p>
				</div>

			</div>
		</div>
		<div id="" class="loginBox">
			<a v-if="!hasLogin" href="/login">
				<p><i class="iconfot">&#xe604;</i>登录</p>
			</a>
			<p v-else> <a href="/peopleInfo" style="padding:0 5px ;">{{user.username}}</a> | <a @click="doLogout" style="padding:0 5px ;">退出</a> </p>
		</div>

	</header>
</template>

<script>
	import {mapState ,mapMutations} from 'vuex'
	import{getWeather} from '@/api/content.js' 
	export default {
		name: "navBox",
		props: {},
		data() {
			return {
				navShow: true,
				navType: '/',
				lALeft: 50,
				selectI: 1,
				WeatherInfo:{},
				todayWeather:{}
			}
		},
		watch: {
		 Detailshow() {
				if (this.$route.fullPath) {
					document.body.scrollTop = 0
				}
			},
		},
		computed: {
			...mapState({
				hasLogin: state => state.user.hasLogin,
				user:state=>state.user.user
			}),
		},
		mounted() {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			// window.addEventListener('scroll', this.handleNavScroll);
			this.navType = this.$route.path
			getWeather().then(res=>{
				console.log(res);
				this.WeatherInfo=res.data.lives[0]
				
				
				console.log(res.data.data);
			})
		},
		
		created() {
			console.log(this.$route.path)
			console.log(this.hasLogin)
		},
		methods: {
			...mapMutations({
				login:'user/login',
				logout:'user/logout'
			}),
			selectStyle(i) {
				this.selectI = i
				if (i == 1) {
					this.lALeft = 50
				} else {
					this.lALeft = (50 * 1) + (172 * (i - 1))
				}
			},
			OverStyle() {
				let path = this.$route.path;
				if (path == '/' || path == '/survey' || path == '/hotspot' || path == '/mediaFocus') {
					this.selectI = 1
				} else if (path == '/humanity') {
					this.selectI = 2
				} else if (path == '/gonglue' || path == '/four' || path == '/new') {
					this.selectI = 3
				} else if (path == '/traffic' || path == '/site' || path == '/food') {
					this.selectI = 4
				} else if (path == '/three' || path == '/five') {
					this.selectI = 5
				}
				this.selectStyle(this.selectI)
			},

			navOne(i) {

				this.navType = this.$route
				console.log(this.$route)
			},
			doLogout(){
				this.logout();
				location.href="/";
			},
			// handleNavScroll() {
			// 	let scrollTopNav = document.documentElement.scrollTop;
			// 	if (scrollTopNav <= 100) {
			// 		this.navShow = true
			// 	}
			// 	// else{
			// 	// this.navShow=false
			// 	// }
			// 	console.log(document.documentElement.scrollTop);
			// },
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleNavScroll);
		}
	};
</script>
<style scoped lang="less">
	.headerbox {
		width: 100%;
		height: 100px;
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		position: absolute;
		background-color: #E6D8C2;
		z-index: 10;

		.headerbg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.66;
			z-index: -1;
		}

		.coldimg {
			position: absolute;
			top: 0;
			right: 110px;
			width: 158px;
			height: 72px;
			opacity: 0.8;
		}

	}

	.weatherBox {
		position: absolute;
		top: 5px;
		right: 155px;
		text-align: center;

		h3 {
			font-size: 22px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #5A606A;
			line-height: 30px;
			position: relative;

			i {
				position: absolute;
				top: -8px;
				right: 23px;

			}
		}

		h2 {
			font-size: 16px;
			line-height: 18px;
			font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
			font-weight: 400;
			color: #5C6573;
		}

		.weatherNum {
			display: flex;
			align-items: center;

			div {
				padding: 4px 10px;
			}

			i {
				font-size: 16px;
				line-height: 16px;
				color: #5C6573;
			}

			p {
				font-size: 9px;
				line-height: 10px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #5A606A;
			}
		}
	}

	.header_inbox {
		width: 88%;
		margin-right: 2%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	.navbox {
		margin-right: 150px;
		position: relative;

		ul {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}

		li {
			position: relative;
			padding: 30px 50px;

			a {
				height: 25px;
				line-height: 25px;
				font-size: 18px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;

				&:hover {
					color: #AE7520 !important;
				}
			}

			&:hover {
				.navsub {
					opacity: 1;
				}

			}

			.navsub {
				opacity: 0;
				transition: 0.5s;
				position: absolute;
				top: 92px;
				left: 50%;
				margin-left: -58px;
				width: 120px;
				background-color: rgba(0, 0, 0, 0.3);
				z-index: 999999999;
				padding: 10px 0;

				p {
					text-align: center;
					cursor: pointer;

					a {
						height: 34px;
						line-height: 34px;
						font-size: 16px;
						font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
						font-weight: 400;
						color: #FFFFFF;
						letter-spacing: 1px;

						// text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.56);
						&:hover {
							color: #FFFFFF !important;
						}
					}

					&:hover {
						a {
							height: 34px;
							line-height: 34px;
							font-size: 16px;
							font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
							font-weight: 400;
							color: #F2B963 !important;
						}

						// background: linear-gradient(180deg, #F2B963 0%, rgba(30, 123, 176, 0.4) 100%);

					}
				}

			}
		}

		.lion {
			color: #AE7520 !important;
			position: relative;

		}

		.lionActive {
			position: absolute;
			left: 50px;
			bottom: -7px;
			width: 70px;
			height: 3px;
			background-color: #AE7520;
			position: absolute;
			transition: 0.4s;

		}

	}

	.loginBox {
		height: 100px;
		position: absolute;
		right: 20px;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		i {
			vertical-align: middle;
			font-size: 20px;
			margin-right: 5px;
		}

		p {
			font-size: 16px;
			color: #8b919f;

			a {
				color: #8b919f;
			}

			&:hover {

				a {
					color: #27282a;
				}


			}
		}

	}
</style>
