import * as types from './action-types';
import { getBannerList } from '@/api/public'
export default {
    state: {
        ajaxTokens:[], //存放请求，以便于跳转时候取消上个页面请求
        demo:'我是index的数据'
    },
    mutations: {
        // mutation将状态放到bannerList中
      
        [types.SET_REQUEST_TOKEN](state,payload){
            if(payload == 'clear'){
                state.ajaxTokens = [];
            }else{
                state.ajaxTokens = [...state.ajaxTokens,payload]
            }
        }
    },
    actions: {
        // 调用获取轮播图接口,提交到mutation中
        async [types.SET_BANNER_LIST]({ commit }) {
           
        }
    }
}

