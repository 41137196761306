import * as config from './config';
import axios from '@/utils/axios'


export const login = (options) =>{
    if(options.username && options.password && options.code && options.uid){
        return axios.post(config.login,options)
    }
    return Promise.reject('登录参数不正确')
}

// 这个用户的验证码

export const validate = () => axios.get(config.validate)

export const sendSmsCode =(mobile)=>{
	return axios.get('wx/api/wx/account/sendCode',{params:{mobile:mobile}})
}

export const mobileLogin =(data)=>{
	return axios.get('wx/api/wx/account/mobileLogin',{params:data})
}