import axios from 'axios';

export const getArticleList = (params)=>{
	const axiosInstance = axios.create();
	return axiosInstance({
		url:'https://www.ezhouxishan.com/hdstorApi/app/content/queryList',
		method:'get',
		params:params
	})
}

export const getArticleDetail = (id)=>{
	const axiosInstance = axios.create();
	return axiosInstance({
		url:'https://www.ezhouxishan.com/hdstorApi/app/content/queryOne/'+id,
		method:'get',
	})
}

export const getWeather = (id)=>{
	const axiosInstance = axios.create();
	return axiosInstance({
		url:'https://restapi.amap.com/v3/weather/weatherInfo?city=420700&key=5219630f3f6b65fcde3c2099e5998289',
		method:'get',
	})
}

