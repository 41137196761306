import * as types from '@/store/action-types';
import {setLocal,getLocal} from '@/utils/local'
import router from '@/router/index'
export default {
    state: {
        hasLogin:false,
		user:{}
    },
    mutations: {
       login(state, data) {
       	if(!data){
       		return;
       	}
       	state.hasLogin = true;
       	state.user = data.userInfo;
		localStorage.setItem('userInfo' ,JSON.stringify(data.userInfo))
		localStorage.setItem('tokenInfo' ,JSON.stringify(data.tokenInfo))
       },
       logout(state) {
       	state.hasLogin = false
       	state.user = {};
		localStorage.removeItem('userInfo')
		localStorage.removeItem('token')
       },
    },
    actions: {
        
    },
}