<template>
	<footer class="footerbox">
		<el-row  type="flex" class="row-bg" justify="center">
			<el-col :span="20" :xs="24">
				<div id="" class="footertop">
					<span id="">
						关于我们
					</span>
					<span id="">
						3209622
					</span>
					<span id="">
						湖北省鄂州市鄂城区公园路9号
					</span>
				</div>
				<div id="" class="footerbottom flex" style="justify-content: space-between;">
					<div id="" class="flex leftbottom">
						<img src="../assets/image/logo.png">
								<img src="../assets/img/4a.png" style=" width: auto;margin-right: 0;">
						<div id="" class="leftbFont" v-if="false">
							<p>官方权威预订平台</p>
							<p>一价全包畅享超值服务</p>
							<p>CCO服务保障高品质度假</p>
							<p>7x24小时服务</p>
						</div>
						<ul>
							<li class="title">印象·西山</li>
							<li> <a href="/survey">西山概况</a> </li>
							<li> <a href="/hotspot">景点图集</a> </li>
							<li> <a href="/mediaFocus">媒体聚焦</a></li>
						</ul>
						<ul>
							<li class="title">人文·西山</li>
							<li> <a href="/hotspot">吴王古都</a> </li>
							<li> <a href="/hotspot">千年古刹</a> </li>
							<li> <a href="/hotspot">洗墨池</a> </li>
							<li> <a href="/hotspot">九曲亭</a> </li>
							<li> <a href="/hotspot">吴王城遗址</a> </li>
							<li> <a href="/humanity">西山名人</a> </li>
						</ul>
						<ul>
							<li class="title">足迹·西山</li>
							<li> <a href="/four">游园景点</a> </li>
							<li> <a href="/gonglue">游园攻略</a> </li>
							<li> <a href="/new">游园游记</a> </li>
						</ul>
						<ul>
							<li class="title">出行·西山</li>
							<li> <a href="/traffic">交通信息</a> </li>
							<li> <a href="/site">酒店住宿</a> </li>
							<li> <a href="/food">饕餮美食</a> </li>
							
						</ul>
						<ul>
							<li class="title">服务·西山</li>
							<li> <a href="/three">门票</a> </li>
							<li> <a href="/five">问题解答</a> </li>
						</ul>
					</div>
					<div id="" class="flex rightbox">
						<div id="">
							<img src="../images/qrcode.jpg">
							<p>微信公众号</p>
						</div>
						<!-- <div id="">
							<img src="../images/qrcode.jpg">
							<p>微博</p>
						</div> -->
					</div>
					
				</div>
				<div id="" class="footerfoot flex">
					<div id="" class="footerul flex">
					<p>版权声明</p>
					</div>
					<div id="" class="footerul flex footerfright">
					<p>Copyright 鄂州市西山风景区管理处 </p>  <p>  版权所有</p>	 <p> 鄂ICP备2022007195号-1  </p>	 
					<!-- <p> 鄂公网安备100000000号 </p>	 -->
					</div>
					
				</div>
			</el-col>
		</el-row>
	</footer>
</template>
<script>
	export default {
		name: "navBox",
		props: {},
	};
</script>
<style scoped lang="less">
	.flex {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.footerbox {
		background-color: #172126;
	}

	.footertop {
		height: 70px;
		line-height: 70px;
		border-bottom: 1px solid #303E44;

		span {
			font-size: 14px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #FCFCFC;
			line-height: 0px;
			padding-right: 35px;

			&:nth-child(1) {
				font-size: 18px;
			}

		}
	}

	.footerbottom {
		padding-bottom: 40px;
		.leftbottom {
			img {
				margin: 40px 30px 0 0;
				width: 103px;
				height: 95px;

			}

			.leftbFont {
				margin-top: 40px;

				p {
					line-height: 26px;
					font-size: 14px;
					font-family: SimSun-Regular, SimSun;
					font-weight: 400;
					color: #9B9B9B;

				}
			}

			ul {
				margin-top: 26px;
				margin-left: 80px;

				.title {
					height: 21px;
					line-height: 21px;
					font-size: 16px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					margin-bottom: 24px;
				}

				li {
					cursor: pointer;
					
					a{
						line-height: 20px;
						font-size: 14px;
						font-family: SimSun-Regular, SimSun;
						font-weight: 400;
						color: #77797A;
					}
					&:hover {
						a{
						color: #FFFFFF!important;	
						}
						
					}
				}
			}
		}

		.rightbox {
			// width: 261px;
			height: 154px;
			text-align: center;
			justify-content: space-around;
			align-items: center;
			margin-top: 26px;
			margin-left: 15px;
			img {
				width: 100px;
				height: 100px;
			}

			p {
				line-height: 38px;
				font-size: 18px;
				color: #FFFFFF;
			}
		}
	}
	.footerfoot{
		border-top: 1px solid #303E44;
		font-size: 14px;
		font-family: SimSun-Regular, SimSun;
		height: 64px;
		line-height: 64px;
		justify-content: space-between;
		.footerul{
			font-family: SimSun-Regular, SimSun;
			font-weight: 400;
			color: #9B9B9B;
			p{
				cursor: pointer;
				margin-right: 30px;
				&:hover {
					color: #FFFFFF;
				}
			}
		}
		.footerfright{
			p{
				margin-right: 5px;
			}
			
		}
		
	}
</style>
