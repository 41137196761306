import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from '@/api/index.js'
import Directives from '@/directives'
import dataV from '@jiaminghi/data-view'
import * as echarts from 'echarts'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
import 'animate.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import navBox from "@/components/navBox.vue";
import footerBox from "@/components/footerBox.vue"
import Title from '@/components/Title.vue'

Vue.component('navBox',navBox)
Vue.component('footerBox',footerBox)
Vue.component('Title',Title)
Vue.prototype.$video = Video
Vue.use(dataV)
Vue.use(Directives)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.prototype.$echarts = echarts
    //向上通知
Vue.prototype.$dispatch = function(eventName, value) {
        let parent = this.$parent;
        while (parent) {
            parent.$emit(eventName, value)
            parent = parent.$parent;
        }
    }
    //向下通知
Vue.prototype.$broadcast = function(eventName, value) {
    const broadcast = (children) => {
        children.forEach(child => {
            child.$emit(eventName, value);
            if (child.$children) {
                broadcast(child.$children)
            }
        })
    }
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')