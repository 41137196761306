<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>
<script>
	import {mapState ,mapMutations} from 'vuex'
	export default{
		computed: {
			...mapState({
				hasLogin: state => state.user.hasLogin,
			}),
		},
		created() {
			let userInfo =localStorage.getItem('userInfo');
			let tokenInfo =localStorage.getItem('tokenInfo');
			let currentTime =new Date().getTime();
			userInfo = userInfo ? JSON.parse(userInfo) :null;
			tokenInfo =  tokenInfo ? JSON.parse(tokenInfo) :null;
			if(userInfo && tokenInfo&& Number(tokenInfo.tokenExpire) > currentTime){
				console.log('hasLogin')
				let data={
					userInfo:userInfo,
					tokenInfo:tokenInfo
				}
				this.login(data);
			}else{
				console.log('has not login')
				this.logout();
			}
		},
		methods: {
			...mapMutations({
				login:'user/login',
				logout:'user/logout'
			}),
		},
	}
</script>
<style lang="less">
	@import "./assets/css/reset.less";
	@import "./assets/css/common.css";
	@import "./assets/css/animate.min.css";
	@import "./assets/css/iconfont.css";
	@import "./assets/css/style.css";
	@import "./assets/css/d_style.css";
	@import "./assets/css/lhl.css";
	@import "./assets/css/styles-desktop.min.css";
	@import  "./assets/css/main.css";
	// #app {
	//   text-align: center;

	// }

	// #nav {
	//   padding: 30px;

	//   a {
	//     font-weight: bold;
	//     color: #2c3e50;

	//     &.router-link-exact-active {
	//       color: #42b983;
	//     }
	//   }
	// }
</style>
