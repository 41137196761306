import * as config from './config';
import axios from '@/utils/axios'

//  接口的调用 如果接口需要将返回的结果放到vuex中，需要把调用放到action中

//首页数据
export const homeData = (params) => {
  
        return axios.get('wx/api/wx/home/index', {
            params:params
        })
  
}
//场地预约
export const siteManage = (params) => {
        return axios.post('wx/api/ticket/siteManage/page',params )
}
//门票预定
export const ticket = (params) => {
        return axios.post('wx/api/wx/ticket/page',params )
}

//获取文章列表
export const getArticles =(params) =>{
	return axios.post('wx/api/ticket/ScenicArticle/page',params);
}

//获取相册
export const getAlbum =(params)=>{
	return axios.post('wx/api/ticket/ScePicGro/selectPicGroup' ,params);
}
export const getPictures =(params)=>{
	return axios.post('wx/api/ticket/ScenicPicture/page',params)
}

//门票详情
export const getTicketDetail = (params) => {
    return axios.get('wx/api/wx/ticket/query',{params:params} )
}
export const getConsume = (params) => {
    return axios.get('wx/api/wx/topUp/queryConsume',params )
}
export const getTraveler = (params) => {
    return axios.post('wx/api/sys/traveler/list',params )
}
//购买门票 
export const buyTicket =(data)=>{
	return axios.post('wx/api/wx/order/add',data)
}

//查询门票订单
export const getOrders =(data)=>{
	return axios.post('wx/api/wx/order/page' ,data)
}
//查询预约订单
export const getSubscribeOrders =(data)=>{
	return axios.post('wx/api/ticket/MessageManage/myMsg' ,data)
}

//查询游客信息
export const getTravels =(data)=>{
	return axios.post('wx/api/sys/traveler/list' ,data) 
}

//添加游客信息
export const createTravel =(data)=>{
	return axios.post('wx/api/sys/traveler/created' ,data) 
}

//删除
export const removeTravel =(id)=>{
	return axios.get('wx/api/sys/traveler/deleted' , {params:{id:id}})
}

//查询门票订单
export const getTickerOrder =(data)=>{
	return axios.get('wx/api/wx/order/queryTicOrder' ,{params:data}) 
}

//微信支付
export const getWxpay =(orderId)=>{
	return axios.get('pay/api/pay/wxpay/nativeWxPay', {params:{orderId:orderId}})
}

//查询门票订单详情

export const getTickerOrderDetail =(data)=>{
	return axios.get('wx/api/wx/order/queryTicketDetail' ,{params:data}) 
}